import React from "react";
import { useSearchParams } from "react-router-dom";

import { SDK_APP_KEY, SDK_APP_URL } from "../../js/variables";
import imgLogo from '../../images/logo.svg';
import imgTitleGetGem from '../../images/title-get-gems.svg';
import imgHeroViaarpeLimitless from '../../images/hero-box-limitless.png';
import imgBrandViaarpeLimitless from '../../images/viaarpelimitliess-brand-logo.png';
import imgCert98kg from '../../images/cert-98kg-thumb.png';
import imgCert135kg from '../../images/cert-135kg-thumb.png';
import imgFooterGem from '../../images/footer-icon-gem.png';

const Gems = () => {
    const [searchParams] = useSearchParams();

    let sdkSrc = `${SDK_APP_URL}/?appKey=${SDK_APP_KEY}`

    if (searchParams.has('referer')) {
        sdkSrc = `${SDK_APP_URL}/?appKey=${SDK_APP_KEY}&referralCode=${searchParams.get('referer')}`
    }

    return (
        <div className="phase2">
            <div className="section1">
                {/* <!-- Page Content --> */}
                <div className="container">

                    {/* <!-- Navigation --> */}
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#app"><img src={imgLogo} width="38" height="33" /></a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#empty"></a>
                                    </li>
                                </ul>
                                <div className="d-flex">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#about">About</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#token">Token</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#legal">Legal</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#community">Community</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://medium.com/@GEMScommunity/how-to-stake-gems-7f1d7c92fc3a" target="_blank">Stake</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://medium.com/@GEMScommunity/2e0db32421c6" target="_blank">Referrals</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>


                    <div className="row">
                        <div className="col mx-auto text-center">
                            <img src={imgTitleGetGem} width="698" height="258" className="img-fluid" alt="Get $GEMS" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center mt-2">
                            <p className="mb-0">Join the $GEMS Community to gain access to Limitless' exclusive club and unlock rewards, all backed by an industry-leading refund policy, and over $600M in collateral...</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center">
                            <div className="sdk-wrapper mx-auto text-center"><iframe className="sdk" allow="clipboard-read; clipboard-write;" src={sdkSrc} frameborder="0" title="Get $GEMS"></iframe></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center mb-5">
                        <p className="gems-link h4"><a href="https://foundationnetwork.org/?initCWidget=true" target="_blank" className="fw-bold">Click here to buy with debit card</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section2">
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                    <div id="about"></div>
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">
                        <div className="col mt-5 mb-3">
                            <h1 className="mb-4">How <span className="text-gems">$GEMS</span> work</h1>
                            <p className="mb-4">By tokenizing our mines, users can purchase $GEMS tokens for $1 USD each (easily swappable on Ethereum and other chains) to unlock gemstone purchasing & polishing, mine tours, and more. $GEMS tokens will also offer all holders staking rewards (up to 20%) and referral rewards (5% on all purchases)</p>
    
                            <p>After 36 months (from launch date), any unused $GEMS are refundable for $4/token worth of ETH (at the price of $ETH in 36 months). We have collateralized the ecosystem, rewards and refunds by placing over $600M into custody of our token launch infrastructure provider, Everest</p>
                            <p className="gems-link"><a href="https://medium.com/@GEMScommunity/gems-faq-4709471c9aec" target="_blank" className="fw-bold">Click here for FAQ</a></p>
                        </div>
                        <div className="col mx-auto text-center">
                            <img src={imgHeroViaarpeLimitless} className="img-fluid" alt="Viaarpe Limitless" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                </div>
            </div>
            <div className="section3">
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                    <div id="token"></div>
                    <div className="row">
                        <div className="col mx-auto text-center mt-5">
                            <h1><span className="text-gems">TOKEN</span> Info</h1>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-5">
                        <div className="col mt-5 align-bottom">
                            <h2>$GEMS</h2>
                            <p><span className="text-gems">SYMBOL</span></p>
                        </div>
                        <div className="col"></div>
                        <div className="col mt-5 align-bottom">
                            <h2>21,000,000</h2>
                            <p><span className="text-gems">TOTAL SUPPLY</span></p>
                        </div>
                        <div className="col"></div>
                        <div className="col mt-5 align-bottom">
                            <h2>$1 USD</h2>
                            <p><span className="text-gems">LAUNCH PRICE</span></p>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-5">
                        <div className="col my-5 align-bottom">
                            <h2>20M <span className="fs-6">Public</span><br />
                                1M <span className="fs-6">Marketing</span></h2>
                            <p><span className="text-gems">ALLOCATION</span></p>
                        </div>
                        <div className="col"></div>
                        <div className="col my-5 align-bottom">
                            <h2>&nbsp;<br />Ethereum</h2>
                            <p><span className="text-gems">NETWORKS</span></p>
                        </div>
                        <div className="col"></div>
                        <div className="col my-5 align-bottom">
                            <h2>18 July<br />
                                2024</h2>
                            <p><span className="text-gems">LAUNCH DATE</span></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                </div>
            </div>
            <div className="section4">
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">
                        <div className="col mx-auto mb-5 text-center phase2-video">
                            <div className="col mx-auto mb-5 text-center phase2-video">
                                <div className="video-player-wrapper">
                                    <iframe src="https://player.vimeo.com/video/967773329?badge=0&amp;autopause=0&amp;player＿id=0&amp;app＿id=58479" frameborder="0" allow="autoplay; fullscreen; picture‒in‒picture; clipboard‒write" style={{position:"absolute",top:0,left:0,width:"100％",height:"100％"}} title="Viaarpe Limitless Appraisal Report"></iframe>
                                </div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col mx-auto text-center my-5">
                                    <a href="https://viaarpelimitless.com/" target="_blank"><img src={imgBrandViaarpeLimitless} className="img-fluid" alt="Viaarpe Limitless" /></a>
                                </div>
                            </div>
                            <div className="row text-center">
                                <p>Viaarpe Limitless is a multi-billion dollar mining conglomerate with mines in Brazil and Mexico, and a 20 year history of supplying minerals, gems, rare earth metals and stones to buyers globally...</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                </div>
            </div>
            <div className="section5">
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto text-center my-5">
                            <h1><span className="text-gems">LEGAL</span> Documents</h1>
                        </div>
                    </div>
                    <div id="legal"></div>
                    <div className="d-flex flex-row flex-wrap flex-shrink-1 justify-content-center mb-5">
                        <div className="p-2">
                            <a href="/images/legal/cert-98kg.png"><img src={imgCert98kg} className="img-fluid" alt="Legal Document 1" /></a>
                        </div>
                        <div className="p-2">
                            <a href="/images/legal/cert-135kg.png"><img src={imgCert135kg} className="img-fluid" alt="Legal Document 2" /></a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center my-2"></div>
                    </div>
                </div>
            </div>
            <div className="section6">
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                    <div id="community"></div>
                    <div className="row">
                        <div className="col mx-auto text-center mt-5">
                            <h1>Join the <span className="text-gems">$GEMS</span> community...</h1>
                        </div>
                    </div>
                    <div className="d-flex flex-row flex-wrap flex-shrink-1 justify-content-center mt-3 mb-5">
                        <div className="p-2">
                            <a href="https://twitter.com/GEMScommunity" target="_blank" className="btn btn-primary fw-bold">X</a>
                        </div>
                        <div className="p-2">
                            <a href="https://t.me/GEMS_Token_Community" target="_blank" className="btn btn-primary fw-bold">Telegram</a>
                        </div>
                        <div className="p-2">
                            <a href="https://t.me/GEMS_Token_News" target="_blank" className="btn btn-primary fw-bold">News</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mx-auto text-center my-5"></div>
                    </div>
                    {/* <!-- Footer --> */}
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 footer">
                        <div className="col mr-auto mb-5">
                            Copyright &copy; 2024 EverLaunch
                        </div>
                        <div className="col mx-auto text-center mb-5">
                            <img src={imgFooterGem} className="img-fluid" alt="" />
                        </div>
                        <div className="col ml-auto text-end mb-5">
                            <a href="/terms">Legal</a>
                            <a href="mailto:contact@everlaunch.org">Contact</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Gems;
